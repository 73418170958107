@import 'global.scss';

.ending {
  :global {
  }
}

.participants {
  >div {
    &:first-child {
      @include container;
    }
  }
  :global {
    @include janssen-card;
    .row {
      align-items: center;
      // header
      &.header {
        @include top-title;
      }
      /*
      &.header {
        max-height: $topHeight;
        background-color:$green3;
        color: white;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex:1;
        h1 {
          font-size: $title-font-size;
          display: inline-block;
          width: 100%;
          padding: 0;
          margin: 0;
        }
      }
      */
      &.body {
        overflow: auto;
        flex:1;
        @include scrollable;
        >.col {
          .row {
            .col {
              position: relative;
              .badge {
                display: block;
                position: absolute;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
              }
            }
          }
        }
      }
      &.footer {
        flex:1;
        max-height: $footerSize;
        .col {
          width: 100% !important;
          height: 100% !important;
          display: flex;
          align-items: center;
          justify-content: center;
          .row {
            width: 100%;
            height: 100%;
            button {
              @include colored-button($blue3);
            }
          }
        }
      }
      align-items: center;
      align-content: space-between;
      .col {
        text-align: center;
        position: relative;
        &.city {
          height: 23vh;
          width: calc(100% - 20px);
          // margin-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            // color: white !important;
          }
        }
      }
    }
  }
}

.questions {
  :global {
    .selectable {
      .col {
        position: relative;
        .pistolero-badge {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 20px;
          height: 20px;
          position: absolute;
          right: 15px;
          top: 10px;
        }
      }
    }
  }
}
.patientCase, .questions {
  >div {
    &:first-child {
      @include container;
    }
  }
  :global {
    @include janssen-card;
    .row {
      &.header {
        @include header;
      }
      &.body {
        flex:1;
        overflow: auto;
        @include scrollable;
        .row {
          min-height: $innerTextContainerSize;
          .col {
            display: flex;
            justify-content: center;
            button {
              @include inner-text-bubble;
            }
          }
        }
      }
      &.footer {
        max-height: $footerSize;
        flex:1;
        .col {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          button {
            @include colored-button($blue3);
          }
        }
      }
    }
  }
}

.questions, .questionDetails {
  >div {
    &:first-child {
      @include container;
    }
  }
  :global {
    @include janssen-card;
    .row {
      &.stats-top {
        max-height: 80px;
        padding-top: 15px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        flex:1;
        >div:nth-child(2) {
          color:white;
          padding-left: 10px;
          max-height: 20px;
          display: flex;
          align-items: center;
        }
        .row {
          flex:1;
          .nav {
            padding: 0;
            margin: 0;
            width: 100%;
            // align-items: center;
            // justify-content: center;
            flex-direction: column;
            display: flex;
            height: 100%;
            >button {
              display: flex;
              justify-content: center;
              align-items: center;
              @include colored-button($blue5);
              width: 80% !important;
              height: 80% !important;
              padding: 2px !important;
              margin: 0 !important;
              font-family: 'Roboto-Regular' !important;
              font-size: 16px !important;
              margin-top: auto !important;
              &.selected {
                background-color: $blue3;
              }
            }
          }
        }
        .col {
          display: flex;
          align-items: center;
          justify-content: center;
          flex:1;
        }
        >div:nth-child(1) {
          position: absolute;
          top: 12px;
          right: 12px;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          button {
            background: none;
            border: none;
            display: inline-block;
            font-size:2em;
            transform: rotate(45deg);
            font-size: 38px !important;
            font-family: 'Roboto-Thin' !important;
            color:white;
            &:focus {
              outline:0;
            }
          }
        }
      }
      &.previous {
        height: 50px;
        display: flex;
        font-family: 'Roboto-Bold';
        .col {
          color: white;
          display: flex;
          align-items: center;
          // font-size:1.8em;
          // font-weight: bold;
          flex:1;
          button {
            background: none;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 25px !important;
          }
          &:first-child {
            max-width: 20%;
            margin-left:10px;
          }
          &:nth-child(2) {
            justify-content: center;
            align-items: center;
            font-size: 24px;
          }
        }
      }
      &.body {
        @include scrollable;
        height: calc(100% - 10% - 100px - 80px) !important;
      }
    }
  }
}

/*
.questionDetails {
  :global {
    .row {
      &.previous {
        height: 80px;
        display: flex;
        .col {
          display: flex;
          align-items: center;
          font-size:2em;
          font-weight: bold;
          &:first-child {
            max-width: 20%;
            margin-left:10px;
          }
        }
      }
      &.stats-details-top {
        height: 50px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .col {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        div:nth-child(1) {
          position: absolute;
          top: 2px;
          right: 2px;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          button {
            background: none;
            border: none;
            display: inline-block;
            font-size:2em;
            transform: rotate(45deg);
            &:focus {
              outline:0;
            }
          }
        }
      }
    }
  }
}*/

.questionDetails {
  >div {
    &:first-child {
      @include container;
    }
  }
  :global {
    @include janssen-card;
    .row {
      color: white;
      &.header {
        @include header;
      }
      &.top-body {
        // flex:1;
        min-height: 70px;
        height: auto;
        .col {
          max-height: 100%;
          >div {
            max-height: 100%;
            // background:red !important;
          }
        }
        >div:first-child{
          text-shadow: $textShadow;
          max-width: calc(20% + 10px);
          >div {
            width: 70%;
            height: 100%;
            // margin-top: 5%;
            margin-left: 20%;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: $green3;
            span {
              line-height: 1em;
              display: inline-block;
              font-size: 1.4em;
              font-weight: bold;
              // max-height: calc(100% / 3);
              font-family: 'Roboto-Bold';
              &:nth-child(2) {
                font-family: 'Roboto-Regular';
                font-size: 1.2em !important;
                font-weight: normal !important;   
              }
            }
          }
        }
        .ques {
          height: 100%;
          >div {
            padding:10px;
            text-align: center;
            display: flex;
            width: calc(100% - 15px);
            height: 100%;
            justify-content: center;
            align-items: center;
            // border: 1px solid grey;
            border-radius: 6px;
            background-color: $green3;
            span {
              display: inline;
              font-family: 'Roboto-Medium';
              line-height: 16px;
              font-size: 16px;
            }
          }
        }
      }
      &.body {
        flex:1;
        @include scrollable;
        .row {
          .col {
            min-height: $innerTextContainerSize;
            &:nth-child(1) {
              max-width: 20%;
              display: flex;
              align-items: center;
              position: relative;
              margin-left:10px;
              div {
                position: absolute;
                background: $green3;
                font-family: 'Roboto-Bold';
                text-shadow: $textShadow;
                position: absolute;
                width: 80%;
                padding-bottom: 80%;
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
                left: 50%;
                border-radius: 50%;
                // border: 1px solid gray;
                span {
                  color: white;
                  position: absolute;
                  top: 50%; left: 50%;
                  transform: translate(-50%, -50%);
                  font-size: 1.5em;
                  font-weight: bold;
                  // text-shadow: 1px 1px 2px black;
                }
              }
            }
          }
          .col {
            height: 100%;
            .ans {
              text-align: center;
              min-height: $innerTextContainerSize;
              height: calc(100% - 10px);
              margin-top:5px;
              width: calc(100% - 15px);
              padding: 10px;
              // border: 1px solid gray;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $blue5;
              font-family: 'Roboto-Medium';
              font-size: 15px;
              line-height: 15px;
            }
          }
        }
      }
      &.footer {
        max-height: $footerSize;
        flex:1;
        .col {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          button {
            @include colored-button($blue3);
          }
        }
        /*min-height: 100px;
        button {
          padding:0;
          width: 60%;
          margin-left: 20%;
          margin-top:10px;
          height: 80%;
        }*/
      }
    }
  }
}

.ranking {
  >div {
    &:first-child {
      @include container;
    }
  }
  :global {
    @include janssen-card;
    .col {
      color: white;
      width: 100%;
      justify-content:flex-start !important;
      .row {
        &.container {
          @include scrollable;
        }
      }
      .ranking-top {
        height: 60px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        h1 {
          font-size: 1.5em;
          padding: 0;
          margin: 0;
          width: auto !important;
        }
        div {
          position: absolute;
          // background-color: yellow;
          top: 12px;
          right: 12px;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          button {
            background: none;
            border: none;
            display: inline-block;
            font-size:2em;
            transform: rotate(45deg);
            color: white;
            font-size: 38px !important;
            font-family: 'Roboto-Thin' !important;
            &:focus {
              outline:0;
            }
          }
        }
      }
      .ranking-row {
        width: 100%;
        text-align: center;
        // background: grey;
        // margin-top: 10px;
        .position {
          display: flex;
          align-items: center;
          justify-content: center!important;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            // background-color: blue;
            border: none;//1px solid rgb(110, 110, 110);
            text-align: center;
            color: white;//rgb(188, 187, 187);
            background-color: $green4;
            font-weight: bold;
            font-size: 1em;
          }
        }
        >.col:not(:nth-child(3)) {
          width: 0px !important;
        }
        >.col:nth-child(3) {
          // background: red;
          padding:2px;
          padding-left:4px;
          padding-right:4px;
          min-width: 65% !important;
          .row {
            background: $green4;
            border-radius: 4px;
            width: 100%;
            .col {
              display: flex;
              align-items: center;
              justify-content: center !important;
            }
            .total {
              max-width:25%;
            }
          }
        }
      }
    }
    .participant.ranking-row {
      min-height: 60px;
      i {
        font-style: normal;
      }
      .col {
        span {
          display: inline-block;
          width: 100%;
          height: 100%;
          // background: pink;
        }
        position: relative;
        justify-content: center;
        align-items: center;
        .badge {
          &:before {
            display: none;
          }
          &:hover {
            text-shadow: $textShadow;
            display: block;
            height: calc(100% - 10px);
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            &:before {
              display: block !important;
              width: 200px;
              height: 16px;
              content:  attr(data-content);
              // background:red;
              position: absolute;
              top:calc(50% - 8px);
              left:calc(50% - 100px);
              z-index: 999;
              border-radius: 5px;
              text-align: center;
              font-size: 16px;
              line-height: 16px;
              font-family: 'Roboto-Bold';
              color: white;
            }
          }
          display: block;
          height: calc(100% - 10px);
          width: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          // pointer-events: none;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        .row {
          height: 100%;
          // background: pink;
          width: calc(100% - 10px);
          height: calc(100% - 10px);
        }
      }
    }
    .ranking-row {
      &:not(.participant) {
        .row {
          background: none !important;
        }
      }
    }
  }
}

.stats {
  :global {
  }
}

.view {
  width: 100%;
  margin: 0;
  padding: 0;
  :global {
    .col, .row {
      padding: 0;
      margin: 0;
    }
  }
}

.welcome {
  :global {
    @include janssen-card;
    .row {
      position: relative;
    }
    .col {
      &.body {
        @include container;
      }
      box-sizing: border-box;
      max-width: 100%;
      position: relative;
      height: 100%;
      .image {
        @include background-logo;
      }
      .bottom {
        // background: red;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // align-items: stretch;
        .row {
          flex: 1;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          // background: blue;
          button {
            @include colored-button($green3);
          }
          .col {
            &:first-child {
              height: $buttonHeight;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            // background: yellow;
            .logo {
              right: 10px;
              height:100%;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              width: 80%;
            }
          }
        }
      }
    }
    .message {
      position: absolute;
      bottom:200px;
    }
  }
}

.patientCase {
  :global {
    button {
      font-family: 'Roboto-Medium';
      font-size: 20px !important;
    }
  }
}

.questions {
  :global {
    button {
      font-family: 'Roboto-Medium';
      font-size: 15px !important;
      line-height: 15px;
    }
  }
}